import { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { Flex } from "antd";
import { useGetDistributorFinData } from "api/reactQuery/queryComponents";
import { BSCoreServicesDtoMarketplaceFinDataDetailDto } from "api/reactQuery/querySchemas";
import Card from "components/Card/Card";
import { COLUMN_TYPE_NAMES, getGridDefaultProps } from "constants/agGridConstants";
import i18next from "i18next";
import { useEffect, useMemo, useState } from "react";
import Cookies from "universal-cookie";

const FinancialInfo = () => {
    const [id, setId] = useState<number | null>(null);

    const colDefs = useMemo<ColDef<BSCoreServicesDtoMarketplaceFinDataDetailDto>[]>(
        () => [
          { field: "productType", headerName: i18next.t("fi_table_product_type") },
          {
            field: "documentType",
            headerName: i18next.t("fi_table_document_type"),
          },
          { field: "documentNo", headerName: i18next.t("fi_table_document_no") },
          {
            field: "documentDate",
            headerName: i18next.t("fi_table_document_date"),
            type: COLUMN_TYPE_NAMES.DATE_COLUMN,
          },
          {
            field: "dueDate",
            headerName: i18next.t("fi_table_due_date"),
            type: COLUMN_TYPE_NAMES.DATE_COLUMN,
          },
          {
            field: "due",
            headerName: i18next.t("fi_table_due"),
          },
          {
            field: "inTerm",
            headerName: i18next.t("fi_table_in_term"),
          },
          {
            field: "balance",
            headerName: i18next.t("fi_table_balance"),
          },
        ],
        []
      );
      
      useEffect(() => {
        const cookies = new Cookies();
        const token = cookies.get("token");
        const distributorId = token?.["DistributorId"] ?? null;
        setId(distributorId)
      }, [])

    const {data, isLoading} = useGetDistributorFinData({
        queryParams: { distributorId: id }
    })

    if (isLoading || !id) {
        return null
    }

    return (
        <>
            <Flex vertical style={{ width: "100%", marginBottom: 20 }}>
                <Flex style={{ width: "100%", gap: 20 }}>
                    <Card
                    title={i18next.t("ttl_debt")}
                    value={data.inTermTotal}
                    />
                    <Card
                    title={i18next.t("ttl_debt_due")}
                    value={data.dueTotal}
                    />
                    <Card
                    title={i18next.t("ttl_balance")}
                    value={data.balanceTotal}
                    />
                </Flex>
            </Flex>

            <AgGridReact
                columnDefs={colDefs}
                rowData={data.details}
                {...getGridDefaultProps()}
            />
        </>
    );
};

export default FinancialInfo;
