import React, { useEffect } from "react";
import styled from "styled-components";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as AuthenticationStore from "../store/AuthenticationReducer";
import * as actions from "./Utils/actions";
import { LogoutIcon, ReportsIcon } from "../svg";
import LogoIMG from "../images/logo.png";
import ShoppingBag from "svg/ShoppingBag";
import Dropdown from "components/Dropdown";
import i18next from "i18next";
import DropdownLanguage from "components/DropdownLanguage";
import { useTranslation } from "react-i18next";

const Logo = styled.a`
  cursor: pointer;
  display: block;
  width: 110px;

  img {
    width: 100%;
    display: block;
  }
`;

const Header = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 30px;
  box-shadow: 0 4px 30px -4px rgba(0, 51, 90, 0.1);
  background: #fff;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  z-index: 80;
`;

const Nav = styled.ul`
  position: relative;
`;

Nav.Item = styled.li`
  position: relative;
  display: inline-block;
  margin: 0 15px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  > a {
    color: ${({ theme }) => theme.colors.dark};
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 18px 0;
    font-weight: 500;
    text-transform: uppercase;

    svg {
      width: 20px;
      height: 20px;
      display: block;
      fill: ${({ theme }) => theme.colors.dark};
      margin-right: 8px;
    }

    &:hover,
    &.active {
      color: ${({ theme }) => theme.colors.default};

      svg {
        fill: ${({ theme }) => theme.colors.default};
      }
    }
  }
`;

function NavMenu() {
  const isAuthenticated = useSelector(
    (state: any) => state.authentication.isAuthenticated,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    const userRole = actions.getUserRole();
    if (userRole) {
      dispatch(AuthenticationStore.actionCreators.changeAuthentication(true));
    }
  }, [dispatch]);

  useEffect(() => {
    const userRole = actions.getUserRole();
    if (isAuthenticated && !userRole) {
      dispatch(AuthenticationStore.actionCreators.changeAuthentication(false));
      navigate("/");
    }
  }, [isAuthenticated, dispatch, navigate]);

  const logout = (event) => {
    event.preventDefault();
    dispatch(AuthenticationStore.actionCreators.changeAuthentication(false));
    navigate("/");
  };

  return isAuthenticated ? (
    <Header>
      <Logo>
        <img src={LogoIMG} alt="Logo" />
      </Logo>

      <Nav>
        <Nav.Item>
          <NavLink to="/financial-info">
            <ReportsIcon />
            {i18next.t("financial_info")}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <Dropdown
            icon={ShoppingBag}
            title={i18next.t("order")}
            items={[
              { to: "/create-order", label: i18next.t("create") },
              { to: "/order-history", label: i18next.t("history") },
            ]}
          />
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/upload-data">
            <ReportsIcon />
            {i18next.t("upload_data")}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/sent-data">
            <ReportsIcon />
            {i18next.t("sent_data")}
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <DropdownLanguage />
        </Nav.Item>
        <Nav.Item>
          <NavLink to="/logout" onClick={logout}>
            <LogoutIcon />
            Logout
          </NavLink>
        </Nav.Item>
      </Nav>
    </Header>
  ) : null;
}

export default NavMenu;
