/**
 * Generated by @openapi-codegen
 *
 * @version v1
 */
import * as reactQuery from "@tanstack/react-query";
import { useQueryContext, QueryContext } from "./queryContext";
import type * as Fetcher from "./queryFetcher";
import { queryFetch } from "./queryFetcher";
import type * as Schemas from "./querySchemas";

export type GetAdminUserPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type GetAdminUserError = Fetcher.ErrorWrapper<{
  status: 401;
  payload: Schemas.MicrosoftAspNetCoreMvcProblemDetails;
}>;

export type GetAdminUserVariables = {
  pathParams: GetAdminUserPathParams;
} & QueryContext["fetcherOptions"];

export const fetchGetAdminUser = (
  variables: GetAdminUserVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    undefined,
    GetAdminUserError,
    undefined,
    {},
    {},
    GetAdminUserPathParams
  >({ url: "/api/users/AdminUser/{id}", method: "get", ...variables, signal });

export const useGetAdminUser = <TData = undefined,>(
  variables: GetAdminUserVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, GetAdminUserError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useQueryContext(options);
  return reactQuery.useQuery<undefined, GetAdminUserError, TData>({
    queryKey: queryKeyFn({
      path: "/api/users/AdminUser/{id}",
      operationId: "getAdminUser",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAdminUser({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetCommentPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type GetCommentError = Fetcher.ErrorWrapper<undefined>;

export type GetCommentVariables = {
  pathParams: GetCommentPathParams;
} & QueryContext["fetcherOptions"];

export const fetchGetComment = (
  variables: GetCommentVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    undefined,
    GetCommentError,
    undefined,
    {},
    {},
    GetCommentPathParams
  >({ url: "/api/Comments/{id}", method: "get", ...variables, signal });

export const useGetComment = <TData = undefined,>(
  variables: GetCommentVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, GetCommentError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useQueryContext(options);
  return reactQuery.useQuery<undefined, GetCommentError, TData>({
    queryKey: queryKeyFn({
      path: "/api/Comments/{id}",
      operationId: "getComment",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetComment({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetCustomerServicePathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type GetCustomerServiceError = Fetcher.ErrorWrapper<undefined>;

export type GetCustomerServiceVariables = {
  pathParams: GetCustomerServicePathParams;
} & QueryContext["fetcherOptions"];

export const fetchGetCustomerService = (
  variables: GetCustomerServiceVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    undefined,
    GetCustomerServiceError,
    undefined,
    {},
    {},
    GetCustomerServicePathParams
  >({
    url: "/api/users/CustomerService/{id}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetCustomerService = <TData = undefined,>(
  variables: GetCustomerServiceVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, GetCustomerServiceError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useQueryContext(options);
  return reactQuery.useQuery<undefined, GetCustomerServiceError, TData>({
    queryKey: queryKeyFn({
      path: "/api/users/CustomerService/{id}",
      operationId: "getCustomerService",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetCustomerService({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetDataLoaderPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type GetDataLoaderError = Fetcher.ErrorWrapper<undefined>;

export type GetDataLoaderVariables = {
  pathParams: GetDataLoaderPathParams;
} & QueryContext["fetcherOptions"];

export const fetchGetDataLoader = (
  variables: GetDataLoaderVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    undefined,
    GetDataLoaderError,
    undefined,
    {},
    {},
    GetDataLoaderPathParams
  >({ url: "/api/users/DataLoader/{id}", method: "get", ...variables, signal });

export const useGetDataLoader = <TData = undefined,>(
  variables: GetDataLoaderVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, GetDataLoaderError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useQueryContext(options);
  return reactQuery.useQuery<undefined, GetDataLoaderError, TData>({
    queryKey: queryKeyFn({
      path: "/api/users/DataLoader/{id}",
      operationId: "getDataLoader",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetDataLoader({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetDistributorPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type GetDistributorError = Fetcher.ErrorWrapper<undefined>;

export type GetDistributorVariables = {
  pathParams: GetDistributorPathParams;
} & QueryContext["fetcherOptions"];

export const fetchGetDistributor = (
  variables: GetDistributorVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    undefined,
    GetDistributorError,
    undefined,
    {},
    {},
    GetDistributorPathParams
  >({
    url: "/api/distributors/Distributor/{id}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetDistributor = <TData = undefined,>(
  variables: GetDistributorVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, GetDistributorError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useQueryContext(options);
  return reactQuery.useQuery<undefined, GetDistributorError, TData>({
    queryKey: queryKeyFn({
      path: "/api/distributors/Distributor/{id}",
      operationId: "getDistributor",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetDistributor({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetDistributorCustomerPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type GetDistributorCustomerError = Fetcher.ErrorWrapper<undefined>;

export type GetDistributorCustomerVariables = {
  pathParams: GetDistributorCustomerPathParams;
} & QueryContext["fetcherOptions"];

export const fetchGetDistributorCustomer = (
  variables: GetDistributorCustomerVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    Schemas.BSCoreWebRequestsResponsesDistributorsDistributorCustomerResponse,
    GetDistributorCustomerError,
    undefined,
    {},
    {},
    GetDistributorCustomerPathParams
  >({
    url: "/api/distributors/DistributorCustomers/{id}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetDistributorCustomer = <
  TData = Schemas.BSCoreWebRequestsResponsesDistributorsDistributorCustomerResponse,
>(
  variables: GetDistributorCustomerVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.BSCoreWebRequestsResponsesDistributorsDistributorCustomerResponse,
      GetDistributorCustomerError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useQueryContext(options);
  return reactQuery.useQuery<
    Schemas.BSCoreWebRequestsResponsesDistributorsDistributorCustomerResponse,
    GetDistributorCustomerError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/api/distributors/DistributorCustomers/{id}",
      operationId: "getDistributorCustomer",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetDistributorCustomer({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetDistributorDepotPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type GetDistributorDepotError = Fetcher.ErrorWrapper<undefined>;

export type GetDistributorDepotVariables = {
  pathParams: GetDistributorDepotPathParams;
} & QueryContext["fetcherOptions"];

export const fetchGetDistributorDepot = (
  variables: GetDistributorDepotVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    undefined,
    GetDistributorDepotError,
    undefined,
    {},
    {},
    GetDistributorDepotPathParams
  >({
    url: "/api/distributors/DistributorDepots/{id}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetDistributorDepot = <TData = undefined,>(
  variables: GetDistributorDepotVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, GetDistributorDepotError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useQueryContext(options);
  return reactQuery.useQuery<undefined, GetDistributorDepotError, TData>({
    queryKey: queryKeyFn({
      path: "/api/distributors/DistributorDepots/{id}",
      operationId: "getDistributorDepot",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetDistributorDepot({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetDistributorFilePathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type GetDistributorFileError = Fetcher.ErrorWrapper<undefined>;

export type GetDistributorFileVariables = {
  pathParams: GetDistributorFilePathParams;
} & QueryContext["fetcherOptions"];

export const fetchGetDistributorFile = (
  variables: GetDistributorFileVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    undefined,
    GetDistributorFileError,
    undefined,
    {},
    {},
    GetDistributorFilePathParams
  >({
    url: "/api/distributors/DistributorFiles/{id}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetDistributorFile = <TData = undefined,>(
  variables: GetDistributorFileVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, GetDistributorFileError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useQueryContext(options);
  return reactQuery.useQuery<undefined, GetDistributorFileError, TData>({
    queryKey: queryKeyFn({
      path: "/api/distributors/DistributorFiles/{id}",
      operationId: "getDistributorFile",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetDistributorFile({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetDProductPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type GetDProductError = Fetcher.ErrorWrapper<undefined>;

export type GetDProductVariables = {
  pathParams: GetDProductPathParams;
} & QueryContext["fetcherOptions"];

export const fetchGetDProduct = (
  variables: GetDProductVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    Schemas.BSCoreWebRequestsResponsesDistributorsDistributorProductResponse,
    GetDProductError,
    undefined,
    {},
    {},
    GetDProductPathParams
  >({
    url: "/api/distributors/DistributorProducts/{id}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetDProduct = <
  TData = Schemas.BSCoreWebRequestsResponsesDistributorsDistributorProductResponse,
>(
  variables: GetDProductVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.BSCoreWebRequestsResponsesDistributorsDistributorProductResponse,
      GetDProductError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useQueryContext(options);
  return reactQuery.useQuery<
    Schemas.BSCoreWebRequestsResponsesDistributorsDistributorProductResponse,
    GetDProductError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/api/distributors/DistributorProducts/{id}",
      operationId: "getDProduct",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetDProduct({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetDistributorUserPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type GetDistributorUserError = Fetcher.ErrorWrapper<undefined>;

export type GetDistributorUserVariables = {
  pathParams: GetDistributorUserPathParams;
} & QueryContext["fetcherOptions"];

export const fetchGetDistributorUser = (
  variables: GetDistributorUserVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    undefined,
    GetDistributorUserError,
    undefined,
    {},
    {},
    GetDistributorUserPathParams
  >({
    url: "/api/users/DistributorUser/{id}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetDistributorUser = <TData = undefined,>(
  variables: GetDistributorUserVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, GetDistributorUserError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useQueryContext(options);
  return reactQuery.useQuery<undefined, GetDistributorUserError, TData>({
    queryKey: queryKeyFn({
      path: "/api/users/DistributorUser/{id}",
      operationId: "getDistributorUser",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetDistributorUser({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetDepotPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type GetDepotError = Fetcher.ErrorWrapper<undefined>;

export type GetDepotVariables = {
  pathParams: GetDepotPathParams;
} & QueryContext["fetcherOptions"];

export const fetchGetDepot = (
  variables: GetDepotVariables,
  signal?: AbortSignal,
) =>
  queryFetch<undefined, GetDepotError, undefined, {}, {}, GetDepotPathParams>({
    url: "/api/hermes/HermesDepot/{id}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetDepot = <TData = undefined,>(
  variables: GetDepotVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, GetDepotError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useQueryContext(options);
  return reactQuery.useQuery<undefined, GetDepotError, TData>({
    queryKey: queryKeyFn({
      path: "/api/hermes/HermesDepot/{id}",
      operationId: "getDepot",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetDepot({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetPocPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type GetPocError = Fetcher.ErrorWrapper<undefined>;

export type GetPocVariables = {
  pathParams: GetPocPathParams;
} & QueryContext["fetcherOptions"];

export const fetchGetPoc = (variables: GetPocVariables, signal?: AbortSignal) =>
  queryFetch<
    Schemas.BSCoreWebRequestsResponsesHermesPocResponse,
    GetPocError,
    undefined,
    {},
    {},
    GetPocPathParams
  >({ url: "/api/hermes/HermesPoc/{id}", method: "get", ...variables, signal });

export const useGetPoc = <
  TData = Schemas.BSCoreWebRequestsResponsesHermesPocResponse,
>(
  variables: GetPocVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.BSCoreWebRequestsResponsesHermesPocResponse,
      GetPocError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useQueryContext(options);
  return reactQuery.useQuery<
    Schemas.BSCoreWebRequestsResponsesHermesPocResponse,
    GetPocError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/api/hermes/HermesPoc/{id}",
      operationId: "getPoc",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPoc({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetSkuPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type GetSkuError = Fetcher.ErrorWrapper<undefined>;

export type GetSkuVariables = {
  pathParams: GetSkuPathParams;
} & QueryContext["fetcherOptions"];

export const fetchGetSku = (variables: GetSkuVariables, signal?: AbortSignal) =>
  queryFetch<
    Schemas.BSCoreWebRequestsResponsesHermesSkuResponse,
    GetSkuError,
    undefined,
    {},
    {},
    GetSkuPathParams
  >({ url: "/api/hermes/HermesSku/{id}", method: "get", ...variables, signal });

export const useGetSku = <
  TData = Schemas.BSCoreWebRequestsResponsesHermesSkuResponse,
>(
  variables: GetSkuVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.BSCoreWebRequestsResponsesHermesSkuResponse,
      GetSkuError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useQueryContext(options);
  return reactQuery.useQuery<
    Schemas.BSCoreWebRequestsResponsesHermesSkuResponse,
    GetSkuError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/api/hermes/HermesSku/{id}",
      operationId: "getSku",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetSku({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetTenantPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type GetTenantError = Fetcher.ErrorWrapper<undefined>;

export type GetTenantVariables = {
  pathParams: GetTenantPathParams;
} & QueryContext["fetcherOptions"];

export const fetchGetTenant = (
  variables: GetTenantVariables,
  signal?: AbortSignal,
) =>
  queryFetch<undefined, GetTenantError, undefined, {}, {}, GetTenantPathParams>(
    { url: "/api/ManageTenants/{id}", method: "get", ...variables, signal },
  );

export const useGetTenant = <TData = undefined,>(
  variables: GetTenantVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, GetTenantError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useQueryContext(options);
  return reactQuery.useQuery<undefined, GetTenantError, TData>({
    queryKey: queryKeyFn({
      path: "/api/ManageTenants/{id}",
      operationId: "getTenant",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetTenant({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetDistributorFinDataQueryParams = {
  /**
   * @format int32
   */
  distributorId?: number;
};

export type GetDistributorFinDataError = Fetcher.ErrorWrapper<undefined>;

export type GetDistributorFinDataVariables = {
  queryParams?: GetDistributorFinDataQueryParams;
} & QueryContext["fetcherOptions"];

export const fetchGetDistributorFinData = (
  variables: GetDistributorFinDataVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    Schemas.BSCoreServicesDtoMarketplaceFinDataDto,
    GetDistributorFinDataError,
    undefined,
    {},
    GetDistributorFinDataQueryParams,
    {}
  >({
    url: "/api/MpFinancialInfo/GetDistributorFinData",
    method: "get",
    ...variables,
    signal,
  });

export const useGetDistributorFinData = <
  TData = Schemas.BSCoreServicesDtoMarketplaceFinDataDto,
>(
  variables: GetDistributorFinDataVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.BSCoreServicesDtoMarketplaceFinDataDto,
      GetDistributorFinDataError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useQueryContext(options);
  return reactQuery.useQuery<
    Schemas.BSCoreServicesDtoMarketplaceFinDataDto,
    GetDistributorFinDataError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/api/MpFinancialInfo/GetDistributorFinData",
      operationId: "getDistributorFinData",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetDistributorFinData({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetImportHistoryQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type GetImportHistoryError = Fetcher.ErrorWrapper<undefined>;

export type GetImportHistoryResponse =
  Schemas.BSCoreServicesDtoMarketplaceImportHistoryDto[];

export type GetImportHistoryVariables = {
  queryParams?: GetImportHistoryQueryParams;
} & QueryContext["fetcherOptions"];

export const fetchGetImportHistory = (
  variables: GetImportHistoryVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    GetImportHistoryResponse,
    GetImportHistoryError,
    undefined,
    {},
    GetImportHistoryQueryParams,
    {}
  >({
    url: "/api/MpImport/GetImportHistory",
    method: "get",
    ...variables,
    signal,
  });

export const useGetImportHistory = <TData = GetImportHistoryResponse,>(
  variables: GetImportHistoryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetImportHistoryResponse,
      GetImportHistoryError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useQueryContext(options);
  return reactQuery.useQuery<
    GetImportHistoryResponse,
    GetImportHistoryError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/api/MpImport/GetImportHistory",
      operationId: "getImportHistory",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetImportHistory({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type ImportProductAvailabilityError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: string;
}>;

export type ImportProductAvailabilityRequestBody = {
  /**
   * @format binary
   */
  file?: Blob;
};

export type ImportProductAvailabilityVariables = {
  body?: ImportProductAvailabilityRequestBody;
} & QueryContext["fetcherOptions"];

export const fetchImportProductAvailability = (
  variables: ImportProductAvailabilityVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    undefined,
    ImportProductAvailabilityError,
    ImportProductAvailabilityRequestBody,
    {},
    {},
    {}
  >({
    url: "/api/MpImport/ImportProductAvailability",
    method: "post",
    ...variables,
    signal,
  });

export const useImportProductAvailability = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ImportProductAvailabilityError,
      ImportProductAvailabilityVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useQueryContext();
  return reactQuery.useMutation<
    undefined,
    ImportProductAvailabilityError,
    ImportProductAvailabilityVariables
  >({
    mutationFn: (variables: ImportProductAvailabilityVariables) =>
      fetchImportProductAvailability({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ImportOnInvoiceError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: string;
}>;

export type ImportOnInvoiceRequestBody = {
  /**
   * @format binary
   */
  file?: Blob;
};

export type ImportOnInvoiceVariables = {
  body?: ImportOnInvoiceRequestBody;
} & QueryContext["fetcherOptions"];

export const fetchImportOnInvoice = (
  variables: ImportOnInvoiceVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    undefined,
    ImportOnInvoiceError,
    ImportOnInvoiceRequestBody,
    {},
    {},
    {}
  >({
    url: "/api/MpImport/ImportOnInvoice",
    method: "post",
    ...variables,
    signal,
  });

export const useImportOnInvoice = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ImportOnInvoiceError,
      ImportOnInvoiceVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useQueryContext();
  return reactQuery.useMutation<
    undefined,
    ImportOnInvoiceError,
    ImportOnInvoiceVariables
  >({
    mutationFn: (variables: ImportOnInvoiceVariables) =>
      fetchImportOnInvoice({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ImportMultiPackError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: string;
}>;

export type ImportMultiPackRequestBody = {
  /**
   * @format binary
   */
  file?: Blob;
};

export type ImportMultiPackVariables = {
  body?: ImportMultiPackRequestBody;
} & QueryContext["fetcherOptions"];

export const fetchImportMultiPack = (
  variables: ImportMultiPackVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    undefined,
    ImportMultiPackError,
    ImportMultiPackRequestBody,
    {},
    {},
    {}
  >({
    url: "/api/MpImport/ImportMultiPack",
    method: "post",
    ...variables,
    signal,
  });

export const useImportMultiPack = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ImportMultiPackError,
      ImportMultiPackVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useQueryContext();
  return reactQuery.useMutation<
    undefined,
    ImportMultiPackError,
    ImportMultiPackVariables
  >({
    mutationFn: (variables: ImportMultiPackVariables) =>
      fetchImportMultiPack({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetOrderQueryParams = {
  /**
   * @format int32
   */
  depotId?: number;
};

export type GetOrderError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MicrosoftAspNetCoreMvcProblemDetails;
}>;

export type GetOrderVariables = {
  queryParams?: GetOrderQueryParams;
} & QueryContext["fetcherOptions"];

export const fetchGetOrder = (
  variables: GetOrderVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    Schemas.BSCoreServicesDtoMarketplaceOrderDto,
    GetOrderError,
    undefined,
    {},
    GetOrderQueryParams,
    {}
  >({ url: "/api/MpOrder/GetOrder", method: "get", ...variables, signal });

export const useGetOrder = <
  TData = Schemas.BSCoreServicesDtoMarketplaceOrderDto,
>(
  variables: GetOrderVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.BSCoreServicesDtoMarketplaceOrderDto,
      GetOrderError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useQueryContext(options);
  return reactQuery.useQuery<
    Schemas.BSCoreServicesDtoMarketplaceOrderDto,
    GetOrderError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/api/MpOrder/GetOrder",
      operationId: "getOrder",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetOrder({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type SaveOrderError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MicrosoftAspNetCoreMvcProblemDetails;
}>;

export type SaveOrderVariables = {
  body?: Schemas.BSCoreServicesDtoMarketplaceOrderDto;
} & QueryContext["fetcherOptions"];

export const fetchSaveOrder = (
  variables: SaveOrderVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    Schemas.BSCoreServicesDtoMarketplaceOrderDto,
    SaveOrderError,
    Schemas.BSCoreServicesDtoMarketplaceOrderDto,
    {},
    {},
    {}
  >({ url: "/api/MpOrder/SaveOrder", method: "post", ...variables, signal });

export const useSaveOrder = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.BSCoreServicesDtoMarketplaceOrderDto,
      SaveOrderError,
      SaveOrderVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useQueryContext();
  return reactQuery.useMutation<
    Schemas.BSCoreServicesDtoMarketplaceOrderDto,
    SaveOrderError,
    SaveOrderVariables
  >({
    mutationFn: (variables: SaveOrderVariables) =>
      fetchSaveOrder({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type CalculateOrderError = Fetcher.ErrorWrapper<undefined>;

export type CalculateOrderVariables = {
  body?: Schemas.BSCoreServicesDtoMarketplaceOrderDto;
} & QueryContext["fetcherOptions"];

export const fetchCalculateOrder = (
  variables: CalculateOrderVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    Schemas.BSCoreServicesDtoMarketplaceOrderDto,
    CalculateOrderError,
    Schemas.BSCoreServicesDtoMarketplaceOrderDto,
    {},
    {},
    {}
  >({
    url: "/api/MpOrder/CalculateOrder",
    method: "post",
    ...variables,
    signal,
  });

export const useCalculateOrder = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.BSCoreServicesDtoMarketplaceOrderDto,
      CalculateOrderError,
      CalculateOrderVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useQueryContext();
  return reactQuery.useMutation<
    Schemas.BSCoreServicesDtoMarketplaceOrderDto,
    CalculateOrderError,
    CalculateOrderVariables
  >({
    mutationFn: (variables: CalculateOrderVariables) =>
      fetchCalculateOrder({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetAllRealOrdersQueryParams = {
  /**
   * @format int32
   */
  depotId?: number;
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type GetAllRealOrdersError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MicrosoftAspNetCoreMvcProblemDetails;
}>;

export type GetAllRealOrdersResponse =
  Schemas.BSCoreServicesDtoMarketplaceOrderDto[];

export type GetAllRealOrdersVariables = {
  queryParams?: GetAllRealOrdersQueryParams;
} & QueryContext["fetcherOptions"];

export const fetchGetAllRealOrders = (
  variables: GetAllRealOrdersVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    GetAllRealOrdersResponse,
    GetAllRealOrdersError,
    undefined,
    {},
    GetAllRealOrdersQueryParams,
    {}
  >({
    url: "/api/MpOrder/GetAllRealOrders",
    method: "get",
    ...variables,
    signal,
  });

export const useGetAllRealOrders = <TData = GetAllRealOrdersResponse,>(
  variables: GetAllRealOrdersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllRealOrdersResponse,
      GetAllRealOrdersError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useQueryContext(options);
  return reactQuery.useQuery<
    GetAllRealOrdersResponse,
    GetAllRealOrdersError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/api/MpOrder/GetAllRealOrders",
      operationId: "getAllRealOrders",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllRealOrders({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type DownloadMpOrdersHistoryPerDepotError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MicrosoftAspNetCoreMvcProblemDetails;
}>;

export type DownloadMpOrdersHistoryPerDepotVariables = {
  body?: Schemas.BSCoreServicesDtoMarketplaceOrderDto;
} & QueryContext["fetcherOptions"];

export const fetchDownloadMpOrdersHistoryPerDepot = (
  variables: DownloadMpOrdersHistoryPerDepotVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    Schemas.SystemIOFile,
    DownloadMpOrdersHistoryPerDepotError,
    Schemas.BSCoreServicesDtoMarketplaceOrderDto,
    {},
    {},
    {}
  >({
    url: "/api/MpOrder/DownloadMpOrdersHistoryPerDepot",
    method: "post",
    ...variables,
    signal,
  });

export const useDownloadMpOrdersHistoryPerDepot = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.SystemIOFile,
      DownloadMpOrdersHistoryPerDepotError,
      DownloadMpOrdersHistoryPerDepotVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useQueryContext();
  return reactQuery.useMutation<
    Schemas.SystemIOFile,
    DownloadMpOrdersHistoryPerDepotError,
    DownloadMpOrdersHistoryPerDepotVariables
  >({
    mutationFn: (variables: DownloadMpOrdersHistoryPerDepotVariables) =>
      fetchDownloadMpOrdersHistoryPerDepot({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpsertProductError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MicrosoftAspNetCoreMvcProblemDetails;
}>;

export type UpsertProductRequestBody = {
  /**
   * @format int32
   */
  Id?: number;
  /**
   * @minLength 3
   */
  Name: string;
  /**
   * @format binary
   */
  ImageFile?: Blob;
  /**
   * @minLength 3
   */
  SAPNumber: string;
  /**
   * @minLength 3
   */
  Barcode: string;
  /**
   * @maximum 9999
   * @minimum 1
   * @format int32
   */
  UnitsInPallet: number;
  /**
   * @maximum 9999
   * @minimum 0
   * @format double
   */
  VolumeInHL: number;
  /**
   * @maximum 9999
   * @minimum 0
   * @format double
   */
  Weight: number;
  /**
   * @maximum 99999
   * @minimum 0
   * @format double
   */
  InvoicePrice: number;
  /**
   * @format int32
   */
  SortIndex?: number;
  IsActive?: boolean;
  IsMultiPack?: boolean;
  GroupType?: Schemas.BSDALMarketplaceEnumsPalletGroupType;
  /**
   * @maximum 2147483647
   * @minimum 1
   * @format int32
   */
  PalletId: number;
  /**
   * @maximum 2147483647
   * @minimum 1
   * @format int32
   */
  PackageId: number;
  /**
   * @format date-time
   */
  ["Availability.ExpiryDate"]?: string;
  /**
   * @format int32
   */
  ["Availability.CurrentAvailableUnits"]?: number;
  ["Availability.ImportComment"]?: string;
  /**
   * @format int32
   */
  ["Availability.NextAvailableUnits"]?: number;
  /**
   * @format date-time
   */
  ["Availability.NextAvailableUnitsDate"]?: string;
  IsImageDeleted?: boolean;
  Prices?: Schemas.BSCoreServicesDtoMarketplaceProductDistributorPriceGroupDto[];
};

export type UpsertProductVariables = {
  body: UpsertProductRequestBody;
} & QueryContext["fetcherOptions"];

export const fetchUpsertProduct = (
  variables: UpsertProductVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    undefined,
    UpsertProductError,
    UpsertProductRequestBody,
    {},
    {},
    {}
  >({
    url: "/api/MpProduct/UpsertProduct",
    method: "post",
    ...variables,
    signal,
  });

export const useUpsertProduct = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpsertProductError,
      UpsertProductVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useQueryContext();
  return reactQuery.useMutation<
    undefined,
    UpsertProductError,
    UpsertProductVariables
  >({
    mutationFn: (variables: UpsertProductVariables) =>
      fetchUpsertProduct({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetAllProductsError = Fetcher.ErrorWrapper<undefined>;

export type GetAllProductsResponse =
  Schemas.BSCoreServicesDtoMarketplaceProductDto[];

export type GetAllProductsVariables = QueryContext["fetcherOptions"];

export const fetchGetAllProducts = (
  variables: GetAllProductsVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    GetAllProductsResponse,
    GetAllProductsError,
    undefined,
    {},
    {},
    {}
  >({
    url: "/api/MpProduct/GetAllProducts",
    method: "get",
    ...variables,
    signal,
  });

export const useGetAllProducts = <TData = GetAllProductsResponse,>(
  variables: GetAllProductsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllProductsResponse,
      GetAllProductsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useQueryContext(options);
  return reactQuery.useQuery<
    GetAllProductsResponse,
    GetAllProductsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/api/MpProduct/GetAllProducts",
      operationId: "getAllProducts",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllProducts({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpsertPalletError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MicrosoftAspNetCoreMvcProblemDetails;
}>;

export type UpsertPalletVariables = {
  body: Schemas.BSCoreServicesDtoMarketplacePalletDto;
} & QueryContext["fetcherOptions"];

export const fetchUpsertPallet = (
  variables: UpsertPalletVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    undefined,
    UpsertPalletError,
    Schemas.BSCoreServicesDtoMarketplacePalletDto,
    {},
    {},
    {}
  >({
    url: "/api/MpProduct/UpsertPallet",
    method: "post",
    ...variables,
    signal,
  });

export const useUpsertPallet = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpsertPalletError,
      UpsertPalletVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useQueryContext();
  return reactQuery.useMutation<
    undefined,
    UpsertPalletError,
    UpsertPalletVariables
  >({
    mutationFn: (variables: UpsertPalletVariables) =>
      fetchUpsertPallet({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetAllPalletsError = Fetcher.ErrorWrapper<undefined>;

export type GetAllPalletsResponse =
  Schemas.BSCoreServicesDtoMarketplacePalletDto[];

export type GetAllPalletsVariables = QueryContext["fetcherOptions"];

export const fetchGetAllPallets = (
  variables: GetAllPalletsVariables,
  signal?: AbortSignal,
) =>
  queryFetch<GetAllPalletsResponse, GetAllPalletsError, undefined, {}, {}, {}>({
    url: "/api/MpProduct/GetAllPallets",
    method: "get",
    ...variables,
    signal,
  });

export const useGetAllPallets = <TData = GetAllPalletsResponse,>(
  variables: GetAllPalletsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllPalletsResponse,
      GetAllPalletsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useQueryContext(options);
  return reactQuery.useQuery<GetAllPalletsResponse, GetAllPalletsError, TData>({
    queryKey: queryKeyFn({
      path: "/api/MpProduct/GetAllPallets",
      operationId: "getAllPallets",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllPallets({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpsertPackagingError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MicrosoftAspNetCoreMvcProblemDetails;
}>;

export type UpsertPackagingVariables = {
  body?: Schemas.BSCoreServicesDtoMarketplacePackagingDto;
} & QueryContext["fetcherOptions"];

export const fetchUpsertPackaging = (
  variables: UpsertPackagingVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    undefined,
    UpsertPackagingError,
    Schemas.BSCoreServicesDtoMarketplacePackagingDto,
    {},
    {},
    {}
  >({
    url: "/api/MpProduct/UpsertPackaging",
    method: "post",
    ...variables,
    signal,
  });

export const useUpsertPackaging = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpsertPackagingError,
      UpsertPackagingVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useQueryContext();
  return reactQuery.useMutation<
    undefined,
    UpsertPackagingError,
    UpsertPackagingVariables
  >({
    mutationFn: (variables: UpsertPackagingVariables) =>
      fetchUpsertPackaging({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetAllPackagingError = Fetcher.ErrorWrapper<undefined>;

export type GetAllPackagingResponse =
  Schemas.BSCoreServicesDtoMarketplacePackagingDto[];

export type GetAllPackagingVariables = QueryContext["fetcherOptions"];

export const fetchGetAllPackaging = (
  variables: GetAllPackagingVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    GetAllPackagingResponse,
    GetAllPackagingError,
    undefined,
    {},
    {},
    {}
  >({
    url: "/api/MpProduct/GetAllPackaging",
    method: "get",
    ...variables,
    signal,
  });

export const useGetAllPackaging = <TData = GetAllPackagingResponse,>(
  variables: GetAllPackagingVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllPackagingResponse,
      GetAllPackagingError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useQueryContext(options);
  return reactQuery.useQuery<
    GetAllPackagingResponse,
    GetAllPackagingError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/api/MpProduct/GetAllPackaging",
      operationId: "getAllPackaging",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllPackaging({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpsertProductDistributorQueryParams = {
  /**
   * @format int32
   */
  productId?: number;
};

export type UpsertProductDistributorError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MicrosoftAspNetCoreMvcProblemDetails;
}>;

export type UpsertProductDistributorRequestBody =
  Schemas.BSCoreServicesDtoMarketplaceProductDistributorDto[];

export type UpsertProductDistributorVariables = {
  body?: UpsertProductDistributorRequestBody;
  queryParams?: UpsertProductDistributorQueryParams;
} & QueryContext["fetcherOptions"];

export const fetchUpsertProductDistributor = (
  variables: UpsertProductDistributorVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    undefined,
    UpsertProductDistributorError,
    UpsertProductDistributorRequestBody,
    {},
    UpsertProductDistributorQueryParams,
    {}
  >({
    url: "/api/MpProduct/UpsertProductDistributor",
    method: "post",
    ...variables,
    signal,
  });

export const useUpsertProductDistributor = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpsertProductDistributorError,
      UpsertProductDistributorVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useQueryContext();
  return reactQuery.useMutation<
    undefined,
    UpsertProductDistributorError,
    UpsertProductDistributorVariables
  >({
    mutationFn: (variables: UpsertProductDistributorVariables) =>
      fetchUpsertProductDistributor({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetAllDistributorsForProductQueryParams = {
  /**
   * @format int32
   */
  productId?: number;
};

export type GetAllDistributorsForProductError = Fetcher.ErrorWrapper<undefined>;

export type GetAllDistributorsForProductResponse =
  Schemas.BSCoreServicesDtoMarketplaceProductDistributorDto[];

export type GetAllDistributorsForProductVariables = {
  queryParams?: GetAllDistributorsForProductQueryParams;
} & QueryContext["fetcherOptions"];

export const fetchGetAllDistributorsForProduct = (
  variables: GetAllDistributorsForProductVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    GetAllDistributorsForProductResponse,
    GetAllDistributorsForProductError,
    undefined,
    {},
    GetAllDistributorsForProductQueryParams,
    {}
  >({
    url: "/api/MpProduct/GetAllDistributorsForProduct",
    method: "get",
    ...variables,
    signal,
  });

export const useGetAllDistributorsForProduct = <
  TData = GetAllDistributorsForProductResponse,
>(
  variables: GetAllDistributorsForProductVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllDistributorsForProductResponse,
      GetAllDistributorsForProductError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useQueryContext(options);
  return reactQuery.useQuery<
    GetAllDistributorsForProductResponse,
    GetAllDistributorsForProductError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/api/MpProduct/GetAllDistributorsForProduct",
      operationId: "getAllDistributorsForProduct",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllDistributorsForProduct(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type UpsertInvoiceProductDistributorQueryParams = {
  /**
   * @format int32
   */
  productId?: number;
};

export type UpsertInvoiceProductDistributorError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MicrosoftAspNetCoreMvcProblemDetails;
}>;

export type UpsertInvoiceProductDistributorRequestBody =
  Schemas.BSCoreServicesDtoMarketplaceInvoiceProductDistributorDto[];

export type UpsertInvoiceProductDistributorVariables = {
  body?: UpsertInvoiceProductDistributorRequestBody;
  queryParams?: UpsertInvoiceProductDistributorQueryParams;
} & QueryContext["fetcherOptions"];

export const fetchUpsertInvoiceProductDistributor = (
  variables: UpsertInvoiceProductDistributorVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    undefined,
    UpsertInvoiceProductDistributorError,
    UpsertInvoiceProductDistributorRequestBody,
    {},
    UpsertInvoiceProductDistributorQueryParams,
    {}
  >({
    url: "/api/MpProduct/UpsertInvoiceProductDistributor",
    method: "post",
    ...variables,
    signal,
  });

export const useUpsertInvoiceProductDistributor = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      UpsertInvoiceProductDistributorError,
      UpsertInvoiceProductDistributorVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useQueryContext();
  return reactQuery.useMutation<
    undefined,
    UpsertInvoiceProductDistributorError,
    UpsertInvoiceProductDistributorVariables
  >({
    mutationFn: (variables: UpsertInvoiceProductDistributorVariables) =>
      fetchUpsertInvoiceProductDistributor({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetAllDistributorsForInvoiceProductQueryParams = {
  /**
   * @format int32
   */
  productId?: number;
};

export type GetAllDistributorsForInvoiceProductError =
  Fetcher.ErrorWrapper<undefined>;

export type GetAllDistributorsForInvoiceProductResponse =
  Schemas.BSCoreServicesDtoMarketplaceInvoiceProductDistributorDto[];

export type GetAllDistributorsForInvoiceProductVariables = {
  queryParams?: GetAllDistributorsForInvoiceProductQueryParams;
} & QueryContext["fetcherOptions"];

export const fetchGetAllDistributorsForInvoiceProduct = (
  variables: GetAllDistributorsForInvoiceProductVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    GetAllDistributorsForInvoiceProductResponse,
    GetAllDistributorsForInvoiceProductError,
    undefined,
    {},
    GetAllDistributorsForInvoiceProductQueryParams,
    {}
  >({
    url: "/api/MpProduct/GetAllDistributorsForInvoiceProduct",
    method: "get",
    ...variables,
    signal,
  });

export const useGetAllDistributorsForInvoiceProduct = <
  TData = GetAllDistributorsForInvoiceProductResponse,
>(
  variables: GetAllDistributorsForInvoiceProductVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetAllDistributorsForInvoiceProductResponse,
      GetAllDistributorsForInvoiceProductError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useQueryContext(options);
  return reactQuery.useQuery<
    GetAllDistributorsForInvoiceProductResponse,
    GetAllDistributorsForInvoiceProductError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/api/MpProduct/GetAllDistributorsForInvoiceProduct",
      operationId: "getAllDistributorsForInvoiceProduct",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetAllDistributorsForInvoiceProduct(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetRealOrderReportQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type GetRealOrderReportError = Fetcher.ErrorWrapper<undefined>;

export type GetRealOrderReportVariables = {
  queryParams?: GetRealOrderReportQueryParams;
} & QueryContext["fetcherOptions"];

export const fetchGetRealOrderReport = (
  variables: GetRealOrderReportVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    Schemas.SystemIOFile,
    GetRealOrderReportError,
    undefined,
    {},
    GetRealOrderReportQueryParams,
    {}
  >({
    url: "/api/MpReport/GetRealOrderReport",
    method: "post",
    ...variables,
    signal,
  });

export const useGetRealOrderReport = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.SystemIOFile,
      GetRealOrderReportError,
      GetRealOrderReportVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useQueryContext();
  return reactQuery.useMutation<
    Schemas.SystemIOFile,
    GetRealOrderReportError,
    GetRealOrderReportVariables
  >({
    mutationFn: (variables: GetRealOrderReportVariables) =>
      fetchGetRealOrderReport({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetOnTimeDeliveryReportQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type GetOnTimeDeliveryReportError = Fetcher.ErrorWrapper<undefined>;

export type GetOnTimeDeliveryReportVariables = {
  queryParams?: GetOnTimeDeliveryReportQueryParams;
} & QueryContext["fetcherOptions"];

export const fetchGetOnTimeDeliveryReport = (
  variables: GetOnTimeDeliveryReportVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    Schemas.SystemIOFile,
    GetOnTimeDeliveryReportError,
    undefined,
    {},
    GetOnTimeDeliveryReportQueryParams,
    {}
  >({
    url: "/api/MpReport/GetOnTimeDeliveryReport",
    method: "post",
    ...variables,
    signal,
  });

export const useGetOnTimeDeliveryReport = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.SystemIOFile,
      GetOnTimeDeliveryReportError,
      GetOnTimeDeliveryReportVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useQueryContext();
  return reactQuery.useMutation<
    Schemas.SystemIOFile,
    GetOnTimeDeliveryReportError,
    GetOnTimeDeliveryReportVariables
  >({
    mutationFn: (variables: GetOnTimeDeliveryReportVariables) =>
      fetchGetOnTimeDeliveryReport({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetDifferenceReportQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type GetDifferenceReportError = Fetcher.ErrorWrapper<undefined>;

export type GetDifferenceReportVariables = {
  queryParams?: GetDifferenceReportQueryParams;
} & QueryContext["fetcherOptions"];

export const fetchGetDifferenceReport = (
  variables: GetDifferenceReportVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    Schemas.SystemIOFile,
    GetDifferenceReportError,
    undefined,
    {},
    GetDifferenceReportQueryParams,
    {}
  >({
    url: "/api/MpReport/GetDifferenceReport",
    method: "post",
    ...variables,
    signal,
  });

export const useGetDifferenceReport = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.SystemIOFile,
      GetDifferenceReportError,
      GetDifferenceReportVariables
    >,
    "mutationFn"
  >,
) => {
  const { fetcherOptions } = useQueryContext();
  return reactQuery.useMutation<
    Schemas.SystemIOFile,
    GetDifferenceReportError,
    GetDifferenceReportVariables
  >({
    mutationFn: (variables: GetDifferenceReportVariables) =>
      fetchGetDifferenceReport({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetMpDistributorsError = Fetcher.ErrorWrapper<undefined>;

export type GetMpDistributorsResponse =
  Schemas.BSCoreWebRequestsResponsesDistributorsDistributorResponse[];

export type GetMpDistributorsVariables = QueryContext["fetcherOptions"];

export const fetchGetMpDistributors = (
  variables: GetMpDistributorsVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    GetMpDistributorsResponse,
    GetMpDistributorsError,
    undefined,
    {},
    {},
    {}
  >({
    url: "/api/marketplace/MpSupplyEntities/GetMpDistributors",
    method: "get",
    ...variables,
    signal,
  });

export const useGetMpDistributors = <TData = GetMpDistributorsResponse,>(
  variables: GetMpDistributorsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetMpDistributorsResponse,
      GetMpDistributorsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useQueryContext(options);
  return reactQuery.useQuery<
    GetMpDistributorsResponse,
    GetMpDistributorsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/api/marketplace/MpSupplyEntities/GetMpDistributors",
      operationId: "getMpDistributors",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetMpDistributors({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetMpDistributorDepotsQueryParams = {
  /**
   * @format int32
   */
  distributorId?: number;
};

export type GetMpDistributorDepotsError = Fetcher.ErrorWrapper<undefined>;

export type GetMpDistributorDepotsResponse =
  Schemas.BSCoreWebRequestsResponsesDistributorsDistributorDepotResponse[];

export type GetMpDistributorDepotsVariables = {
  queryParams?: GetMpDistributorDepotsQueryParams;
} & QueryContext["fetcherOptions"];

export const fetchGetMpDistributorDepots = (
  variables: GetMpDistributorDepotsVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    GetMpDistributorDepotsResponse,
    GetMpDistributorDepotsError,
    undefined,
    {},
    GetMpDistributorDepotsQueryParams,
    {}
  >({
    url: "/api/marketplace/MpSupplyEntities/GetMpDistributorDepots",
    method: "get",
    ...variables,
    signal,
  });

export const useGetMpDistributorDepots = <
  TData = GetMpDistributorDepotsResponse,
>(
  variables: GetMpDistributorDepotsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetMpDistributorDepotsResponse,
      GetMpDistributorDepotsError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useQueryContext(options);
  return reactQuery.useQuery<
    GetMpDistributorDepotsResponse,
    GetMpDistributorDepotsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/api/marketplace/MpSupplyEntities/GetMpDistributorDepots",
      operationId: "getMpDistributorDepots",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetMpDistributorDepots({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetMpDistributorsWithSapPayerIdError =
  Fetcher.ErrorWrapper<undefined>;

export type GetMpDistributorsWithSapPayerIdResponse =
  Schemas.BSCoreWebRequestsResponsesDistributorsDistributorResponse[];

export type GetMpDistributorsWithSapPayerIdVariables =
  QueryContext["fetcherOptions"];

export const fetchGetMpDistributorsWithSapPayerId = (
  variables: GetMpDistributorsWithSapPayerIdVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    GetMpDistributorsWithSapPayerIdResponse,
    GetMpDistributorsWithSapPayerIdError,
    undefined,
    {},
    {},
    {}
  >({
    url: "/api/marketplace/MpSupplyEntities/GetMpDistributorsWithSapPayerId",
    method: "get",
    ...variables,
    signal,
  });

export const useGetMpDistributorsWithSapPayerId = <
  TData = GetMpDistributorsWithSapPayerIdResponse,
>(
  variables: GetMpDistributorsWithSapPayerIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetMpDistributorsWithSapPayerIdResponse,
      GetMpDistributorsWithSapPayerIdError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useQueryContext(options);
  return reactQuery.useQuery<
    GetMpDistributorsWithSapPayerIdResponse,
    GetMpDistributorsWithSapPayerIdError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/api/marketplace/MpSupplyEntities/GetMpDistributorsWithSapPayerId",
      operationId: "getMpDistributorsWithSapPayerId",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetMpDistributorsWithSapPayerId(
        { ...fetcherOptions, ...variables },
        signal,
      ),
    ...options,
    ...queryOptions,
  });
};

export type GetPocMappingPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type GetPocMappingError = Fetcher.ErrorWrapper<undefined>;

export type GetPocMappingVariables = {
  pathParams: GetPocMappingPathParams;
} & QueryContext["fetcherOptions"];

export const fetchGetPocMapping = (
  variables: GetPocMappingVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    Schemas.BSCoreWebRequestsResponsesHermesPocMappingResponse,
    GetPocMappingError,
    undefined,
    {},
    {},
    GetPocMappingPathParams
  >({
    url: "/api/hermes/PocMappings/{id}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetPocMapping = <
  TData = Schemas.BSCoreWebRequestsResponsesHermesPocMappingResponse,
>(
  variables: GetPocMappingVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.BSCoreWebRequestsResponsesHermesPocMappingResponse,
      GetPocMappingError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useQueryContext(options);
  return reactQuery.useQuery<
    Schemas.BSCoreWebRequestsResponsesHermesPocMappingResponse,
    GetPocMappingError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/api/hermes/PocMappings/{id}",
      operationId: "getPocMapping",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetPocMapping({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type GetError = Fetcher.ErrorWrapper<undefined>;

export type GetVariables = {
  pathParams: GetPathParams;
} & QueryContext["fetcherOptions"];

export const fetchGet = (variables: GetVariables, signal?: AbortSignal) =>
  queryFetch<
    Schemas.BSCoreWebRequestsResponsesHermesSkuMappingResponse,
    GetError,
    undefined,
    {},
    {},
    GetPathParams
  >({
    url: "/api/hermes/SkuMappings/{id}",
    method: "get",
    ...variables,
    signal,
  });

export const useGet = <
  TData = Schemas.BSCoreWebRequestsResponsesHermesSkuMappingResponse,
>(
  variables: GetVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.BSCoreWebRequestsResponsesHermesSkuMappingResponse,
      GetError,
      TData
    >,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useQueryContext(options);
  return reactQuery.useQuery<
    Schemas.BSCoreWebRequestsResponsesHermesSkuMappingResponse,
    GetError,
    TData
  >({
    queryKey: queryKeyFn({
      path: "/api/hermes/SkuMappings/{id}",
      operationId: "get",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGet({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetTenantAdminPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type GetTenantAdminError = Fetcher.ErrorWrapper<undefined>;

export type GetTenantAdminVariables = {
  pathParams: GetTenantAdminPathParams;
} & QueryContext["fetcherOptions"];

export const fetchGetTenantAdmin = (
  variables: GetTenantAdminVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    undefined,
    GetTenantAdminError,
    undefined,
    {},
    {},
    GetTenantAdminPathParams
  >({
    url: "/api/users/TenantAdmin/{id}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetTenantAdmin = <TData = undefined,>(
  variables: GetTenantAdminVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, GetTenantAdminError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useQueryContext(options);
  return reactQuery.useQuery<undefined, GetTenantAdminError, TData>({
    queryKey: queryKeyFn({
      path: "/api/users/TenantAdmin/{id}",
      operationId: "getTenantAdmin",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetTenantAdmin({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetTenantSupervisorPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type GetTenantSupervisorError = Fetcher.ErrorWrapper<undefined>;

export type GetTenantSupervisorVariables = {
  pathParams: GetTenantSupervisorPathParams;
} & QueryContext["fetcherOptions"];

export const fetchGetTenantSupervisor = (
  variables: GetTenantSupervisorVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    undefined,
    GetTenantSupervisorError,
    undefined,
    {},
    {},
    GetTenantSupervisorPathParams
  >({
    url: "/api/users/TenantSupervisor/{id}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetTenantSupervisor = <TData = undefined,>(
  variables: GetTenantSupervisorVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, GetTenantSupervisorError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useQueryContext(options);
  return reactQuery.useQuery<undefined, GetTenantSupervisorError, TData>({
    queryKey: queryKeyFn({
      path: "/api/users/TenantSupervisor/{id}",
      operationId: "getTenantSupervisor",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetTenantSupervisor({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetTenantSupportPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type GetTenantSupportError = Fetcher.ErrorWrapper<undefined>;

export type GetTenantSupportVariables = {
  pathParams: GetTenantSupportPathParams;
} & QueryContext["fetcherOptions"];

export const fetchGetTenantSupport = (
  variables: GetTenantSupportVariables,
  signal?: AbortSignal,
) =>
  queryFetch<
    undefined,
    GetTenantSupportError,
    undefined,
    {},
    {},
    GetTenantSupportPathParams
  >({
    url: "/api/users/TenantSupport/{id}",
    method: "get",
    ...variables,
    signal,
  });

export const useGetTenantSupport = <TData = undefined,>(
  variables: GetTenantSupportVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, GetTenantSupportError, TData>,
    "queryKey" | "queryFn" | "initialData"
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useQueryContext(options);
  return reactQuery.useQuery<undefined, GetTenantSupportError, TData>({
    queryKey: queryKeyFn({
      path: "/api/users/TenantSupport/{id}",
      operationId: "getTenantSupport",
      variables,
    }),
    queryFn: ({ signal }) =>
      fetchGetTenantSupport({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type QueryOperation =
  | {
      path: "/api/users/AdminUser/{id}";
      operationId: "getAdminUser";
      variables: GetAdminUserVariables;
    }
  | {
      path: "/api/Comments/{id}";
      operationId: "getComment";
      variables: GetCommentVariables;
    }
  | {
      path: "/api/users/CustomerService/{id}";
      operationId: "getCustomerService";
      variables: GetCustomerServiceVariables;
    }
  | {
      path: "/api/users/DataLoader/{id}";
      operationId: "getDataLoader";
      variables: GetDataLoaderVariables;
    }
  | {
      path: "/api/distributors/Distributor/{id}";
      operationId: "getDistributor";
      variables: GetDistributorVariables;
    }
  | {
      path: "/api/distributors/DistributorCustomers/{id}";
      operationId: "getDistributorCustomer";
      variables: GetDistributorCustomerVariables;
    }
  | {
      path: "/api/distributors/DistributorDepots/{id}";
      operationId: "getDistributorDepot";
      variables: GetDistributorDepotVariables;
    }
  | {
      path: "/api/distributors/DistributorFiles/{id}";
      operationId: "getDistributorFile";
      variables: GetDistributorFileVariables;
    }
  | {
      path: "/api/distributors/DistributorProducts/{id}";
      operationId: "getDProduct";
      variables: GetDProductVariables;
    }
  | {
      path: "/api/users/DistributorUser/{id}";
      operationId: "getDistributorUser";
      variables: GetDistributorUserVariables;
    }
  | {
      path: "/api/hermes/HermesDepot/{id}";
      operationId: "getDepot";
      variables: GetDepotVariables;
    }
  | {
      path: "/api/hermes/HermesPoc/{id}";
      operationId: "getPoc";
      variables: GetPocVariables;
    }
  | {
      path: "/api/hermes/HermesSku/{id}";
      operationId: "getSku";
      variables: GetSkuVariables;
    }
  | {
      path: "/api/ManageTenants/{id}";
      operationId: "getTenant";
      variables: GetTenantVariables;
    }
  | {
      path: "/api/MpFinancialInfo/GetDistributorFinData";
      operationId: "getDistributorFinData";
      variables: GetDistributorFinDataVariables;
    }
  | {
      path: "/api/MpImport/GetImportHistory";
      operationId: "getImportHistory";
      variables: GetImportHistoryVariables;
    }
  | {
      path: "/api/MpOrder/GetOrder";
      operationId: "getOrder";
      variables: GetOrderVariables;
    }
  | {
      path: "/api/MpOrder/GetAllRealOrders";
      operationId: "getAllRealOrders";
      variables: GetAllRealOrdersVariables;
    }
  | {
      path: "/api/MpProduct/GetAllProducts";
      operationId: "getAllProducts";
      variables: GetAllProductsVariables;
    }
  | {
      path: "/api/MpProduct/GetAllPallets";
      operationId: "getAllPallets";
      variables: GetAllPalletsVariables;
    }
  | {
      path: "/api/MpProduct/GetAllPackaging";
      operationId: "getAllPackaging";
      variables: GetAllPackagingVariables;
    }
  | {
      path: "/api/MpProduct/GetAllDistributorsForProduct";
      operationId: "getAllDistributorsForProduct";
      variables: GetAllDistributorsForProductVariables;
    }
  | {
      path: "/api/MpProduct/GetAllDistributorsForInvoiceProduct";
      operationId: "getAllDistributorsForInvoiceProduct";
      variables: GetAllDistributorsForInvoiceProductVariables;
    }
  | {
      path: "/api/marketplace/MpSupplyEntities/GetMpDistributors";
      operationId: "getMpDistributors";
      variables: GetMpDistributorsVariables;
    }
  | {
      path: "/api/marketplace/MpSupplyEntities/GetMpDistributorDepots";
      operationId: "getMpDistributorDepots";
      variables: GetMpDistributorDepotsVariables;
    }
  | {
      path: "/api/marketplace/MpSupplyEntities/GetMpDistributorsWithSapPayerId";
      operationId: "getMpDistributorsWithSapPayerId";
      variables: GetMpDistributorsWithSapPayerIdVariables;
    }
  | {
      path: "/api/hermes/PocMappings/{id}";
      operationId: "getPocMapping";
      variables: GetPocMappingVariables;
    }
  | {
      path: "/api/hermes/SkuMappings/{id}";
      operationId: "get";
      variables: GetVariables;
    }
  | {
      path: "/api/users/TenantAdmin/{id}";
      operationId: "getTenantAdmin";
      variables: GetTenantAdminVariables;
    }
  | {
      path: "/api/users/TenantSupervisor/{id}";
      operationId: "getTenantSupervisor";
      variables: GetTenantSupervisorVariables;
    }
  | {
      path: "/api/users/TenantSupport/{id}";
      operationId: "getTenantSupport";
      variables: GetTenantSupportVariables;
    };
