import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import * as AuthenticationStore from "../../store/AuthenticationReducer";
import Form from "../Form";
import TextInput from "../TextInput";
import AuthenticationScreen from "../AuthenticationScreen";
import { Navigate } from "react-router-dom";
import withRouter, { WithRouterHocProps } from "../../helpers/WithRouterHOC";

type AuthenticationProps = AuthenticationStore.AuthenticationState &
  typeof AuthenticationStore.actionCreators;
class LoginForm extends React.PureComponent<
  AuthenticationProps & WithRouterHocProps
> {
  constructor(props) {
    super(props);
  }

  state = {
    username: "",
    password: "",
    validated: false,
  };

  handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.props.attemptAuthentication({
      username: this.state.username,
      password: this.state.password,
    });
    this.setState({
      validated: true,
    });
    event.preventDefault();
    event.stopPropagation();
  };

  onChange = (event) => {
    var validity = event.target.validity;
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  public render() {
    const { isAuthenticated, responseMessage, isLoading } = this.props;
    return isAuthenticated ? (
      <Navigate to="/financial-info" />
    ) : (
      <AuthenticationScreen
        additionalLink={{ to: "/passwordRecovery", label: "Forgot password?" }}
      >
        <Form
          {...{ isLoading, responseMessage }}
          onSubmit={this.handleSubmit}
          buttonText="Login"
          buttonSize="extraLarge"
          buttonJustify="center"
        >
          <TextInput
            label="Username"
            name="username"
            id="username"
            size="large"
            required
            //validator={(value) => value.trim().length !== 0} or e.g. use validator.isEmail
            //errorMessage={{ validator: "Please enter a valid email" }}
            value={this.state.username}
            onChange={this.onChange}
          />
          <TextInput
            label="Password"
            name="password"
            id="password"
            size="large"
            type="password"
            required
            value={this.state.password}
            onChange={this.onChange}
          />
        </Form>
      </AuthenticationScreen>
    );
  }
}

export default withRouter(
  connect(
    (state: ApplicationState) => state.authentication,
    AuthenticationStore.actionCreators,
  )(LoginForm),
);
