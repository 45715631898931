import React from "react";

import styled from "styled-components";

const Card: React.FC<{
  title: string;
  value: number;
}> = ({ title, value }) => (
  <StyledCardContainer>
    <StyledCardTitle>{title}</StyledCardTitle>
    <StyledCardValue $hasValue={!!value}>
      {value.toLocaleString("en", {
        maximumFractionDigits: 4,
        minimumFractionDigits: 0,
        useGrouping: false,
      })}
    </StyledCardValue>
  </StyledCardContainer>
);

export default Card;

const StyledCardContainer = styled.div`
  width: 19.5%;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 8px 16px;
  background: #F9F9F9;
  border-radius: 4px;
  box-shadow: 0 4px 4px 0 #0000000f;
  pointer-events: none;
  text-align: end;
`;
const StyledCardTitle = styled.h4`
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: #6d6d6d;
`;
const StyledCardValue = styled.span<{
  $hasValue?: boolean;
}>`
  font-size: 16px;
  font-weight: 800;
  line-height: 30px;
  color: ${(props) =>
    props.$hasValue ? "#181818" : "#b3b3b3"};
`;